import { Image, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Utils
import { scrollTo } from "../../../utils/helperScrolls";

// Components
import Button from "../../Button";
import DropdownLocale from "../../DropdownLocale";
import ButtonDonateNow from "../../ButtonDonateNow";

// Styles
import { Wrapper, Color } from "./styles";

const Wireframe = ({ dirName }) => {
  const { t } = useTranslation();

  return (
    <Wrapper
      titleColor="#E2E2E2"
      backgroundImageURL={`/assets/${dirName}/${Wireframe.displayName}.webp`}
    >
      <div>
        <div className="d-flex header-line">
          <Color color="#C0D63E" />
          <Color color="#94EAED" />
        </div>
        <div className="wrapper-button-i18n">
          <div className="d-flex justify-content-center">
            <Col xs={11} md={10} lg={10} xl={8}>
              <DropdownLocale />
            </Col>
          </div>
        </div>
        <div className="d-flex justify-content-center selo">
          <Col md={3} className="imageSelo">
            <Image
              src="/assets/SELO.webp"
              width={82}
              height={93}
              alt=''
            />
          </Col>
        </div>
        <div className="d-flex header-content">
          <ButtonDonateNow
            primaryColor="#C0D63E"
            secondaryColor="#1B191A"
            borderFixedColor="#1B191A"
            borderFixedHoverColor="#C0D63E"
          />
          <div className="d-flex justify-content-center logo">
            <Col xs={6} className="d-flex justify-content-center">
              <Image
                src={`/assets/${dirName}/${Wireframe.displayName}-logo.webp`}
                width={270}
                height={200}
                alt=''
                fluid
              />
            </Col>
          </div>
          <div className="d-flex justify-content-center">
            <Col xs={10} md={8} lg={7} xl={6} className="text-center sub-title">
              {t(`${Wireframe.displayName}.subtitle`)}
            </Col>
          </div>
          <div className="d-flex justify-content-center button">
            <Button
              primaryColor="#C0D63E"
              secondaryColor="#1B191A"
              buttonWidthVariable={true}
              onClick={() => {
                scrollTo("COMP00001");
              }}
            >
              {t(`${Wireframe.displayName}.button`)}
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

Wireframe.displayName = "HEAD00002";
export default Wireframe;