import { useState, lazy, Suspense } from "react";

// Components
import SectionCookies from "./components/SectionCookies";
import ButtonDonateNow from "./components/ButtonDonateNow";
import HEAD00002 from "./components/Wireframes/HEAD00002";
import COMP00001 from "./components/Wireframes/COMP00001";

// Translations
import { startI18N } from "./i18n";
import i18nTranslations from "./whitelabels/idhl/i18n";

// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

// Lazy Loaded Components
const COMP00003 = lazy(() => import("./components/Wireframes/COMP00003"));
const COMP00008 = lazy(() => import("./components/Wireframes/COMP00008"));
const COMP00022 = lazy(() => import("./components/Wireframes/COMP00022"));
const COMP00023 = lazy(() => import("./components/Wireframes/COMP00023"));
const COMP_ALLYA = lazy(() => import("./components/Wireframes/COMP_ALLYA"));
const CHEC00001 = lazy(() => import("./components/Wireframes/CHEC00001"));
const FAQS00001 = lazy(() => import("./components/Wireframes/FAQS00001"));
const FOOT00001 = lazy(() => import("./components/Wireframes/FOOT00001"));

const App = () => {
  const [acceptedCookies, setAcceptedCookies] = useState(false);

  let dirName = 'lippi';

  startI18N(i18nTranslations);

  return (
    <>
      <div className="lp-app notranslate">
        <HEAD00002 dirName={dirName} />
        {SectionCookies != '' && acceptedCookies === false && (
          <SectionCookies onAccept={() => setAcceptedCookies(true)} />
        )}
        <Suspense>
          <COMP00003 dirName={dirName} />
        </Suspense>
        <Suspense>
          <COMP00023 dirName={dirName} />
        </Suspense>
        <Suspense>
          <COMP00022 dirName={dirName} />
        </Suspense>
        <Suspense>
          <COMP00001 dirName={dirName} />
        </Suspense>
        <Suspense>
          <COMP00008 dirName={dirName} />
        </Suspense>
        <Suspense>
          <COMP_ALLYA />
        </Suspense>
        <Suspense>
          <CHEC00001 />
        </Suspense>
        <Suspense>
          <FAQS00001 />
        </Suspense>
        <Suspense>
          <FOOT00001 />
        </Suspense>
      </div>
    </>
  );
}

export default App;