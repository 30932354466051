import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Components
import Button from "../../Button";
import SectionTitle from "../../SectionTitle";

// Assets
import { ReactComponent as HearthIcon } from "../../../assets/svgs/heartButtonBig.svg";

// Styles
import { Wrapper } from "./styles";

const COMP00001 = ({ dirName }) => {
  const { t } = useTranslation();

  return (
    <Wrapper
      backgroundImageURL={`/assets/${dirName}/COMP00001.webp`}
      backgroundColor="#C0D63E"
      titleColor="#1B191A"
      id={COMP00001.displayName}
    >
      <div className="d-flex justify-content-center icon">
        <HearthIcon />
      </div>
      <div className="title d-flex justify-content-center">
        <Col xs={10} md={7} lg={5} xl={4}>
          <SectionTitle titleColor="#1B191A" >
            {t(`${COMP00001.displayName}.title`)}
          </SectionTitle>
        </Col>
      </div>
      <div className="sub-title d-flex justify-content-center">
        <Col xs={10} md={8} lg={6} xl={6} dangerouslySetInnerHTML={{ __html: t(`${COMP00001.displayName}.subTitle`) }} />
      </div>
      <div className="d-flex justify-content-center button">
        <Button
          primaryColor="#1B191A"
          secondaryColor="#C0D63E"
        >
          {t(`${COMP00001.displayName}.button`)}
        </Button>
      </div>
    </Wrapper>
  );
};

COMP00001.displayName = "COMP00001";

export default COMP00001;
