import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 450px;
  padding: 60px 0;
  background-color: ${({ backgroundColor }) => backgroundColor};
  background-image: url(${({ backgroundImageURL }) => backgroundImageURL});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  
  .icon svg {
    width: 64px;
    fill: ${({ titleColor }) => titleColor};
  }

  .sub-title {
    margin-top: 20px;
    color: ${({ titleColor }) => titleColor};
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
  }
  
  .button {
    margin-top: 35px;
  }
`