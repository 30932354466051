import styled from "styled-components";

export const WrapperButtonFloating = styled.div`
  position: absolute;
  top: 90px;
  right: 77px;
  z-index: 2;
`

export const WrapperButtonFloatingBottom = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 15px;
  cursor: pointer;
  z-index: 2;

  svg {
    fill: #FFFFFF;
    margin-bottom: 10px;
    width: 22px;
    height: 22px;
  }
`