import i18n_translations from "../../components/PropagoCheckout/i18n/translations";

const i18n = {
  "pt-BR": {
    translations: {
      Page: {
        title: "Transforme Vidas e Construa um Futuro Mais Humano",
      },
      ButtonFloating: {
        button: "Doe Agora",
        buttonBottom: "Faça uma doação",
      },
      HEAD00002: {
        subtitle: `Transforme Vidas: Apoie a Equação e ajude pessoas em situação de vulnerabilidade emocional a se tornarem líderes regenerativos.`,
        button: "Saiba mais",
      },
      COMP00001: {
        title: "Sobre o IDHL",
        subTitle:
          `<p>O IDHL, Instituto de Desenvolvimento Humano Lippi, é especializado no desenvolvimento e na formação de pessoas. É um <b>instituto comprometido com a transformação humana, por meio de Saúde Mental, Emocional e Comportamental.</b></p>
          <p>Inovador, único e revolucionário, o IDHL é uma organização comprometida com a agenda 2030 da ONU.</p>
        `,
        button: "Doe agora",
      },
      COMP00003: {
        title: "O QUE É A EQUAÇÃO ",
        subTitle:
          `<p>A Equação é uma metodologia reconhecida pela ONU, estimulando jovens e adultos como motor de gestão emocional, comportamental e social.</p>
          <p>Os alunos aprovados estarão capacitados como Gestores emocionais – Método A EQUAÇÃO para orientar pessoas interessadas em reconhecer, regular e ampliar suas habilidades emocionais. O certificado será emitido pelo IDHL. Trata-se de uma plataforma online com mais de 220h de conteúdo.</p>
          <p><strong>Juntos, Construindo um Futuro Mais Humano</strong></p>
          <p>Ao doar, você se torna um elo vital na corrente regenerativa da vida, ajudando uma comunidade a ter acesso ao conteúdo da Equação.</p>`,
        button: "Doe agora",
      },
      COMP00008: {
        title: "Seja Parte da Mudança - Faça Sua Doação Hoje",
        subTitle:
          `<p class='left'>Ao doar para a Equação, você está investindo no potencial humano e construindo um legado de esperança. Cada doador é uma peça essencial.</p>
          <p class='left'>Não espere! Ajude-nos com a Equação para um futuro mais compassivo. Junte-se ao IDHL na missão de transformar vidas e criar um impacto duradouro.</p>
          <p class='left'>Seja a Diferença que o Mundo Precisa! </p>`,
        button: "Faça Sua Doação Agora!",
      },
      COMP00022: {
        title: `Como Sua Doação Faz a Diferença?`,
        content: `Cada contribuição para a Equação é uma peça fundamental na construção de um futuro melhor. Veja como suas doações impactam:`,
        button: "Faça A Diferença!",
        imgAlt: "Dolor Ipsum",
        cards: [
          { title: 'Doação recorrente de R$40' },
          { title: 'Doação única de R$400' },
        ],
        bottomContent: "Nos ajude a contribuir levando conhecimento para milhares de pessoas gratuitamente."
      },
      COMP00023: {
        title: '<h2>Por Que Doar Para A Equação?</h2>',
        line1: `<p><i class="icon1"></i>Educação Transformadora</p>
                <ul>
                  <li>Existem muitos trabalhos em comunidades voltados para geração de emprego, educação, arte e esporte, mas poucos voltados para saúde mental, comportamental e emocional, gerando recursos internos para aproveitar os recursos externos.</li><br/>
                  <li>Nós decidimos fortalecer emocionalmente jovens e adultos para saberem buscar, interna ou externamente, forças para mudanças futuras, fornecendo acesso a uma jornada de vida que transcende barreiras socioeconômicas.</li>
                </ul>`,
        line2: `<p><i class="icon1"></i>Oportunidades de Segurança Psicológica</p>
                <ul>
                  <li>Nós entendemos que os principais desafios enfrentados hoje nascem de uma profunda desconexão com a nossa vida interna, as pessoas ao nosso redor e o ambiente onde vivemos.</li><br/>
                  <li>Desde então, muitos cientistas ao redor do mundo se uniram a esses esforços e vem contribuindo para um mundo com mais compaixão, entendimento e conexão. </li>
                </ul>`,
        line3: `<p><i class="icon3"></i>Retorno para a Comunidade</p>
                <ul>
                  <li>
                    Os conhecimentos adquiridos geram um retorno direto para a comunidade. 
                  </li>
                </ul>`,

        line4: `<p><i class="icon4"></i>Oportunidades de Segurança Psicológica</p>
                <ul>
                  <li>
                    Somente em 2021, foram 3 mil pessoas, líderes em suas comunidades, empreendedores, professores otimizados em todo território brasileiro. 
                  </li>
                </ul>`,
        button: "Doe Agora"
      },
      COMP_ALLYA: {
        title: `Faça parte do `,
        description: `
          <p>O processo de <strong>doação</strong> é simples, seguro e fácil. O dinheiro doado é revertido para as nossas ações sociais e filantrópicas. Você pode doar uma única vez ou todos os meses, de forma recorrente. Junte-se a nós hoje na criação de um futuro onde o poder duradouro do amor prevaleça. </p>
          <p><strong>Doe todos os meses</strong> para <strong>IDHL</strong> e participe do nosso <strong>Clube do Bem</strong>, uma parceria altruísta e  filantrópica entre <strong>Propago e Allya.</strong></p>
          <p>Você contará com uma série de <strong>descontos, vantagens e benefícios</strong> em mais de <strong>30 mil estabelecimentos</strong> como: Netshoes, Magazine Luiza, Cinemark, Droga Raia, EspaçoLaser e muito mais! </p>
        `,
        list: [
          {
            area: 'Beleza e bem-estar! -',
            discount: 'Até 80%'
          },
          {
            area: 'Lazer -',
            discount: 'Até 50% OFF'
          },
          {
            area: 'Restaurantes -',
            discount: 'Até 35% OFF'
          },
          {
            area: 'Cursos e Faculdades -',
            discount: 'Até 80%'
          },
        ],
        bottomDescription: `Viu como sua economia pode gerar o bem para todo o ecossistema?`,
        text: `A <strong style="color: #F73D6A;">Allya</strong> tem como objetivo ajudar pessoas do bem  a manterem um padrão de vida mais econômico de forma inteligente, levando consigo a filantropia!`,
      },
      CHEC00001: {
        title: "Sua doação irá <strong>transformar a vida</strong> de alguém.",
        legend: "Apoio",
      },
      FAQS00001: {
        title: "<strong>Perguntas Frequentes</strong> sobre a doação:",
        cards: [
          {
            title: "Devo declarar as doações no imposto de renda?",
            subTitle:
              "Em conformidade com as regras da Receita Federal do Brasil, o doador precisa informar todas as doações realizadas em suas declarações financeiras. Lembrando que, segundo o Regulamento do Imposto de Renda – RIR (Decreto nº 9.580, de 22/11/ 2018), não há dedução no Imposto de Renda de Pessoa Física (IRPF) para doações a projetos como os realizados pelo IDHL.<br /><br />O valor doado deve ser declarado na ficha DOAÇÕES EFETUADAS sob o código 99 – Outras doações, uma vez que se trata de doação não incentivada (não permite dedução do IR a pagar do doador).",
          },
          {
            title: "Quais são as formas de pagamento para doações no site?",
            subTitle:
              "Você pode fazer sua doação, seja ela pontual ou recorrente, via boleto bancário, cartão de crédito (todas as bandeiras) ou via pix.",
          },
          {
            title: "Por que o IDHL emitiu uma cobrança em meu nome?",
            subTitle:
              "Para que os pagamentos sejam mais transparentes e seguros, a Federação Brasileira de Bancos (Febraban) instituiu que todos os boletos sejam registrados em uma plataforma de cobrança desenvolvida pela própria Febraban, que permite aos bancos informar ao cliente toda vez que um novo boleto for emitido em seu nome. Clientes cadastrados no serviço Débito Direto Autorizado (DDA) podem ser informados pelos bancos sobre a cobrança no momento do registro do boleto, pelos canais digitais ou com o envio de torpedos.",
          },
          {
            title: "Se eu não pagar o boleto, meu nome pode ser negativado?",
            subTitle:
              "Não. O pagamento dos boletos enviados pelo IDHL é totalmente facultativo.",
          },
          {
            title:
              "Meu boleto veio com um valor que eu não quero doar, o que devo fazer?",
            subTitle:
              "Você pode escolher algum dos valores sugeridos para doação, ou definir uma outra quantia que seja de sua preferência, clicando no botão “outro valor”. Se o valor do boleto emitido não for igual ao que você pretende doar, pode emitir outro.",
          },
          {
            title: "O que eu faço se meu boleto vier sem código de barras?",
            subTitle:
              "Se você recebeu um boleto sem código de barras, isso indica que você está cadastrado no Débito Direto Autorizado (DDA). Por determinação das novas regras da Febraban, clientes cadastrados no serviço DDA não recebem mais boletos com códigos de barras. Para pagar seu boleto basta autorizar o débito junto ao seu banco, pela internet, pelo telefone ou nos caixas eletrônicos.",
          },
          {
            title: "O que é DDA?",
            subTitle:
              "O Débito Direto Autorizado (DDA) é um sistema criado pela Federação Brasileira de Bancos (Febraban) que substitui a emissão de boletos de cobrança impressos pela cobrança eletrônica dos pagamentos. Para ler o comunicado no site da Febraban, acesse: <a href='https://portal.febraban.org.br/pagina/3051/1088/pt-br/dda' target='_blank'>https://portal.febraban.org.br/pagina/3051/1088/pt-br/dda</a>",
          },
          {
            title: "Como posso me cadastrar no DDA?",
            subTitle:
              "Isso é um serviço oferecido pelos bancos. Para se cadastrar no Débito Direto Autorizado (DDA), basta entrar em contato com o seu banco, por telefone, pela internet ou nos caixas eletrônicos. Mesmo após o cadastro, você continua recebendo o boleto impresso; porém, sem o código de barras.",
          },
          {
            title: "Posso me descadastrar do DDA?",
            subTitle: "Para se descadastrar, entre em contato com o seu banco.",
          },
          {
            title:
              "Quero pagar meu boleto, mas a data de vencimento passou. Preciso emitir segunda via?",
            subTitle:
              "Não. Agora os boletos vencidos podem ser pagos em qualquer banco, pela internet, nos caixas eletrônicos ou em casas lotéricas.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Visite-nos!`,
        iframeTitle: "Localização do escritório de IDHL em São Bernardo do Campo"
      },
      FOOT00001: {
        copyright:
          "Copyright © 2024 - IDHL ® - Todos os direitos podem ser compartilhados com você.",
        copyrightSpan: "Pergunte-nos como.",
      },
      SectionCookies: {
        title: "A IDHL se preocupa com você e com a sua privacidade",
        subTitle:
          "O nosso site usa cookies e outras tecnologias para personalizar a sua experiência e compreender como você e os outros visitantes usam o nosso site. Ao navegar pelo site, coletaremos tais informações para utilizá-las com estas finalidades. Caso não aceite, usaremos os cookies apenas necessários para o correto funcionamento do site",
        button: "Aceito",
      },
      Checkout: i18n_translations["pt-BR"].translations,
    },
  },
  "en-US": {
    translations: {
      Page: {
        title: "Transform Lives and Build a More Humane Future",
      },
      ButtonFloating: {
        button: "Donate Now",
        buttonBottom: "Make a donation",
      },
      HEAD00002: {
        subtitle: `Transform Lives: support the Equation and help individuals in emotional vulnerability become regenerative leaders.`,
        button: "Learn more",
      },
      COMP00001: {
        title: "About IDHL",
        subTitle:
          `<p>The IDHL, Instituto de Desenvolvimento Humano Lippi, specializes in the development and training of individuals. It is an <b>institute committed to human transformation, through Mental, Emotional, and Behavioral Health.</b></p>
              <p>Innovative, unique, and revolutionary, IDHL is an organization committed to the UN's 2030 agenda.</p>
            `,
        button: "Donate now",
      },
      COMP00003: {
        title: "WHAT IS THE EQUATION",
        subTitle:
          `<p>The Equation is a methodology recognized by the UN, stimulating young people and adults as a driving force for emotional, behavioral, and social management.</p>
    
              <p>Approved students will be trained as Emotional Managers – THE EQUATION Method to guide individuals interested in recognizing, regulating, and expanding their emotional skills. The certificate will be issued by IDHL. It's an online platform with over 220 hours of content.</p>
              
              <p><strong>Together, Building a More Human Future</strong></p>
    
              <p>By donating, you become a vital link in the regenerative chain of life, helping a community access Equation content.</p>`,
        button: "Donate now",
      },
      COMP00008: {
        title: "Be Part of the Change - Make Your Donation Today",
        subTitle:
          `<p class='left'>By donating to the Equation, you are investing in human potential and building a legacy of hope. Each donor is an essential piece.</p>
              <p class='left'>Don't wait! Help us with the Equation for a more compassionate future. Join IDHL in the mission to transform lives and create lasting impact.</p>
              <p class='left'>Be the Difference the World Needs! </p>`,
        button: "Make Your Donation Now!",
      },
      COMP00022: {
        title: `How Your Donation Makes a Difference?`,
        content: `Each contribution to the Equation is a key piece in building a better future. See how your donations impact:`,
        button: "Make a Difference!",
        imgAlt: "Lorem Ipsum",
        cards: [
          { title: 'Recurring donation of $40' },
          { title: 'One-time donation of $400' },
        ],
        bottomContent: "Help us contribute by bringing knowledge to thousands of people for free."
      },
      COMP00023: {
        title: '<h2>Why Donate to Equação?</h2>',
        line1: `<p><i class="icon1"></i>Transformative Education</p>
                <ul>
                  <li>There are many community projects focused on job creation, education, art, and sports, but few addressing mental, behavioral, and emotional health—building internal resources to harness external ones.</li><br/> 
                  <li>We've chosen to emotionally empower young people and adults to seek, internally or externally, strengths for future changes, providing access to a life journey that transcends socioeconomic barriers.</li>
                </ul>`,
        line2: `<p><i class="icon1"></i>Opportunities for Psychological Security</p>
                <ul>
                  <li>We understand that the key challenges faced today stem from a profound disconnection with our internal life, the people around us, and the environment in which we live.</li><br/>
                  <li>Many scientists worldwide have joined these efforts, contributing to a world with more compassion, understanding, and connection.</li>
                </ul>`,
        line3: `<p><i class="icon3"></i>Community Impact</p>
                <ul>
                  <li>The acquired knowledge generates a direct impact on the community.</li>
                </ul>`,

        line4: `<p><i class="icon4"></i>Psychological Security Opportunities</p>
                <ul>
                  <li>
                    In 2021 alone, 3,000 individuals—leaders in their communities, entrepreneurs, and teachers—were empowered throughout the Brazilian territory.
                  </li>
                </ul>`,
        button: "Donate Now"
      },
      COMP_ALLYA: {
        title: 'Join the ',
        description: `
          <p>The process of <strong>donation</strong> is simple, secure, and easy. The donated money is reverted to our social and philanthropic actions. You can donate once or every month, recurrently. Join us today in creating a future where the lasting power of love prevails.</p>
          <p><strong>Donate every month</strong> to <strong>IDHL</strong> and participate in our <strong>Clube do Bem</strong> (Club of Good), an altruistic and philanthropic partnership between <strong>Propago and Allya.</strong></p>
          <p>You will have a series of <strong>discounts, advantages, and benefits</strong> in more than <strong>30 thousand establishments</strong> such as: Netshoes, Magazine Luiza, Cinemark, Droga Raia, EspaçoLaser and much more!</p>
        `,
        list: [
          {
            area: 'Beauty and well-being! -',
            discount: 'Up to 80% OFF'
          },
          {
            area: 'Leisure - ',
            discount: 'Up to 50% OFF'
          },
          {
            area: 'Restaurants -',
            discount: 'Up to 35% OFF'
          },
          {
            area: 'Courses and Undergraduations -',
            discount: 'Up to 80% OFF'
          },
        ],
        bottomDescription: `<p>See how your economy can do good for the whole ecosystem?</p>`,
        text: `<strong style="color: #F73D6A;">Allya</strong> aims to aims to help good people maintain a more economical standard of living in an intelligent way, bringing philanthropy with it!`,
      },
      CHEC00001: {
        title: "Your donation will <strong>change someone's life</strong>",
        legend: "By",
      },
      FAQS00001: {
        title: "<strong>Frequently Asked Questions</strong> about the donation:",
        cards: [
          {
            title: "Does IDHL accept donations in kind",
            subTitle:
              "Unfortunately, we cannot accept donations in kind.",
          },
          {
            title: "How can i be sure my online donation has been accepted?",
            subTitle:
              "If you entered a valid email address, you will receive a message confirming your donation. If you don't receive an email within about 15 minutes, please contact us.",
          },
          {
            title: "What are the payment methods for donations on the website?",
            subTitle: "You can make your donation, whether punctual or recurring by credit card."
          },
          {
            title: "Why is monthly giving the best option?",
            subTitle:
              "Monthly giving is the best option for both IDHL and our supporters. It allows us to have a dependable base of support and provides predictable funding. For our supporters it is the easiest way to donate, since your credit card deducts your giving automatically.",
          },
          {
            title: "Is the donation page secure?",
            subTitle: "We are proud to meet strict data security standards in order to process your donation online.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Visit us!`,
        iframeTitle: "Localization of IDHL's office in Ribeirão Preto - SP (Brazil)"
      },
      FOOT00001: {
        copyright:
          "Copyright © 2024 - IDHL ® - All rights can be shared with you.",
        copyrightSpan: "Ask us how.",
      },
      SectionCookies: {
        title: "IDHL worries about your privacy.",
        subTitle:
          "This website uses cookies and other technologies to improve your experience and to analyze the traffic in our website. Once you are browsing we will colect such information for this analysis. If you don't accept it, we will use only cookies that are needed to run the website.",
        button: "I Accept",
      },
      Checkout: i18n_translations.en.translations,
    }
  },
  es: {
    translations: {
      Page: {
        title: "Transforma Vidas y Construye un Futuro Más Humano",
      },
      ButtonFloating: {
        button: "Dona ahora",
        buttonBottom: "Haz una donácion",
      },
      HEAD00002: {
        subtitle: `Transforma Vidas: apoya la Ecuación y ayuda a personas en situación de vulnerabilidad emocional a convertirse en líderes regenerativos.`,
        button: "Más información",
      },
      COMP00001: {
        title: "Sobre el IDHL",
        subTitle:
          `<p>El IDHL, Instituto de Desenvolvimento Humano Lippi, se especializa en el desarrollo y formación de personas. Es un <b>instituto comprometido con la transformación humana, a través de la Salud Mental, Emocional y Comportamental.</b></p>
              <p>Innovador, único y revolucionario, el IDHL es una organización comprometida con la agenda 2030 de la ONU.</p>
            `,
        button: "Dona ahora",
      },
      COMP00003: {
        title: "¿QUÉ ES LA ECUACIÓN",
        subTitle:
          `<p>La Ecuación es una metodología reconocida por la ONU, estimulando a jóvenes y adultos como motor de gestión emocional, comportamental y social.</p>
              <p>Los estudiantes aprobados estarán capacitados como Gestores emocionales – Método LA ECUACIÓN para guiar a personas interesadas en reconocer, regular y ampliar sus habilidades emocionales. El certificado será emitido por el IDHL. Es una plataforma en línea con más de 220 horas de contenido.</p>
              <p><strong>Juntos, Construyendo un Futuro Más Humano</strong></p>
              <p>Al donar, te conviertes en un eslabón vital en la cadena regenerativa de la vida, ayudando a una comunidad a tener acceso al contenido de la Ecuación.</p>`,
        button: "Dona ahora",
      },
      COMP00008: {
        title: "Sé Parte del Cambio - Haz Tu Donación Hoy",
        subTitle:
          `<p class='left'>Al donar a la Ecuación, estás invirtiendo en el potencial humano y construyendo un legado de esperanza. Cada donante es una pieza esencial.</p>
              <p class='left'>¡No esperes! Ayúdanos con la Ecuación para un futuro más compasivo. Únete al IDHL en la misión de transformar vidas y crear un impacto duradero.</p>
              <p class='left'>¡Sé la Diferencia que el Mundo Necesita! </p>`,
        button: "Haz Tu Donación Ahora!",
      },
      COMP00022: {
        title: `¿Cómo Tu Donación Hace la Diferencia?`,
        content: `Cada contribución para la Ecuación es una pieza fundamental en la construcción de un futuro mejor. Mira cómo tus donaciones impactan:`,
        button: "Haz La Diferencia!",
        imgAlt: "Dolor Ipsum",
        cards: [
          { title: 'Donación recurrente de R$ 40' },
          { title: 'Donación única de R$400' },
        ],
        bottomContent: "Ayúdenos a contribuir aportando conocimientos a miles de personas de forma gratuita."
      },
      COMP00023: {
        title: '<h2>¿Por qué donar a La Ecuación?</h2>',
        line1: `<p><i class="icon1"></i>Educación Transformadora</p>
                <ul>
                  <li>Hay muchos proyectos comunitarios enfocados en la generación de empleo, educación, arte y deporte, pero pocos se centran en la salud mental, conductual y emocional, generando recursos internos para aprovechar los recursos externos.</li><br/>
                  <li>Decidimos fortalecer emocionalmente a jóvenes y adultos para que sepan buscar, interna o externamente, fuerzas para cambios futuros, proporcionando acceso a un viaje de vida que trasciende barreras socioeconómicas.
                  </li>
                </ul>`,
        line2: `<p><i class="icon1"></i>Oportunidades de Seguridad Psicológica</p>
                <ul>
                  <li>Entendemos que los principales desafíos que enfrentamos hoy surgen de una profunda desconexión con nuestra vida interna, las personas a nuestro alrededor y el entorno en el que vivimos.</li><br/> 
                  <li>Desde entonces, muchos científicos de todo el mundo se han unido a estos esfuerzos y han estado contribuyendo a un mundo con más compasión, comprensión y conexión.</li>
                </ul>`,
        line3: `<p><i class="icon3"></i>Retorno para la Comunidad</p>
                <ul>
                  <li>Los conocimientos adquiridos generan un retorno directo a la comunidad.</li>
                </ul>`,

        line4: `<p><i class="icon4"></i>Oportunidades de Seguridad Psicológica</p>
                <ul>
                  <li>
                    Solo en 2021, se beneficiaron 3 mil personas: líderes en sus comunidades, emprendedores, y profesores en todo el territorio brasileño.
                  </li>
                </ul>`,
        button: "Dona Ahora"
      },
      COMP_ALLYA: {
        title: '¡Venga y forme parte de nuestro ecosistema del bien!',
        description: `
          <p>El proceso de donación es sencillo, seguro y fácil. O dinheiro doado é revertido para as nossas ações sociais e filantrópicas. Puede donar una vez o cada mes de forma periódica. Únase hoy a nosotros para crear un futuro en el que prevalezca el poder perdurable del amor.</p>
          <p><strong>Dona todos los meses a IDHL</strong> y participa en nuestro <strong>Clube do Bem</strong>, una asociación altruista y filantrópica entre <strong>Propago y Allya.</strong></p>
          <p>¡Disfrutarás de una <strong>serie de descuentos</strong>, ventajas y beneficios en <strong>más de 30.000 establecimientos</strong> como: Netshoes, Magazine Luiza, Cinemark, Droga Raia, EspaçoLaser y muchos más!</p>
        `,
        list: [
          {
            area: 'Belleza y bienestar -',
            discount: 'Hasta el 80% de descuento',
          },
          {
            area: 'Ocio -',
            discount: 'Hasta el 50% de descuento',
          },
          {
            area: 'Restaurantes -',
            discount: 'Hasta el 35% de descuento',
          },
          {
            area: 'Cursos y Universidades -',
            discount: 'Hasta el 80% de descuento',
          },
        ],
        bottomDescription: `<p>¿Ves cómo tu economía puede hacer bien a todo el ecosistema?</p>`,
        text: `El objetivo de <strong style="color: #F73D6A;">Allya</strong> es generar ahorro y practicidad en la vida cotidiana. Con sus ventajas, es posible un estilo de vida más económico e inteligente`,
      },
      CHEC00001: {
        title: "Su donación <strong>transformará la vida</strong> de alguien.",
        legend: "Apoyo",
      },
      FAQS00001: {
        title: "<strong>Preguntas frecuentes</strong> sobre la donación:",
        cards: [
          {
            title: "¿IDHL acepta donaciones en especie (ropa, alimentos, agua, etc.)?",
            subTitle:
              "Desafortunadamente, no podemos aceptar donaciones en especie.",
          },
          {
            title: "¿Cómo puedo estar seguro de que mi donación en línea ha sido aceptada?",
            subTitle:
              "Si ingresaste una dirección de correo electrónico válida, recibirás un mensaje de confirmación de tu donación. Si no recibes un correo electrónico en 15 minutos, contáctenos.",
          },
          {
            title: "¿Cuáles son los métodos de pago para las donaciones en el sitio web?",
            subTitle:
              "Puedes hacer tu donación, ya sea puntual o recurrente con tarjeta de crédito.",
          },
          {
            title: "¿Por qué la donación recurrente es la mejor opción?",
            subTitle:
              "La donación mensual es la mejor opción tanto para IDHL como para nuestros seguidores. Ella nos permite tener una base de apoyo confiable y proporciona financiamiento predecible. Para nuestros seguidores, es la forma más fácil de donar, ya que la tarjeta de crédito descuenta la donación automáticamente..",
          },
          {
            title:
              "¿Es segura la página de donaciones?",
            subTitle:
              "Estamos orgullosos de cumplir con estrictos estándares de seguridad de datos para procesar tu donación en línea.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Haga una visita a nosotros!`,
        iframeTitle: "Ubicación de la oficina de IDHL"
      },
      FOOT00001: {
        copyright:
          "Copyright © 2024 - IDHL® - Todos los derechos pueden ser compartidos con usted.",
        copyrightSpan: "Pregúntanos cómo.",
      },
      SectionCookies: {
        title: "IDHL preocupase contigo e tu privacidad.",
        subTitle:
          "Este sitio web utiliza cookies y otras tecnologias para mejorar y optimizar la experiencia del usuario, bien como para analizar la atividade de los visitantes y su frequência de utilización. Si no aceptas, utilizaremos únicamente las cookies con finalidad técnica, para que la página web funcione.",
        button: "Acepto",
      },
      Checkout: i18n_translations.es.translations,
    }
  },
};

export default i18n;