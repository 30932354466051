import styled from 'styled-components'
import devices from '../../utils/helperGrids'

export const Wrapper = styled.div`
  text-align: center;
  ${({ buttonWidthVariable }) => buttonWidthVariable ? '' : 'width: 250px;'}
  height: 50px;
  padding-right: 14px;
  padding-left: 14px;
  border-radius: 5px;
  display: flex;
  gap: 10px;
  justify-content: space-around;
  align-items: center;
  font-size: 20px;
  border: 2px solid ${({ primaryColor, borderFixedColor }) => borderFixedColor || primaryColor};
  background-color: ${({ primaryColor }) => primaryColor};
  color: ${({ secondaryColor }) => secondaryColor};
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;

  svg {
    fill: ${({ secondaryColor }) => secondaryColor};
  }

  transition: 0.4s;
  :hover {
    background-color: ${({ secondaryColor }) => secondaryColor};
    color: ${({ primaryColor }) => primaryColor};
    svg {
      fill: ${({ primaryColor }) => primaryColor};  
    }
    ${({ borderFixedHoverColor }) => borderFixedHoverColor ? `
      border: 2px solid ${borderFixedHoverColor};
    ` : ''}
  }

  @media ${devices.xs} {
    font-size: 16px;
  }

  @media ${devices.sm} {
    font-size: 18px;
  }
`
